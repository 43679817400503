import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layout';
import EventList from './EventList';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="EventsPage"
    dataName={'datoCmsEventsPage'}
    largestContentfullPaintImg={null}
  />
);
export const query = graphql`
  query DatoCmsEventsPage($locale: String, $id: String) {
    datoCmsEventsPage(locale: $locale, id: { eq: $id }) {
      ...EventsPage
    }
    allDatoCmsEvent(
      locale: $locale
      filter: {
        _allLinkTitleLocales: { elemMatch: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        ...Events
      }
    }
  }
`;

const EventsPage = ({ data, pageContext }: any) => {
  const {
    upcomingEvents,
    pastEvents,
    listTitle,
    displayTypeFilter,
    displayStatusFilter,
    initialRowSize,
    nextRowSize,
    seeMoreLabel,
    noEventFoundText,
    eventStatus,
    eventTypes,
    ...doc
  }: any = data.datoCmsEventsPage;

  if (!doc) return null;

  const pageData = {
    locale: pageContext.locale,
    upcomingEvents,
    pastEvents,
    listTitle,
    displayTypeFilter,
    displayStatusFilter,
    initialRowSize,
    nextRowSize,
    seeMoreLabel,
    noEventFoundText,
    eventStatus,
    eventTypes,
  };
  const datoEvents = data.allDatoCmsEvent?.nodes ?? [];
  const hero = doc.hero[0];

  const tracking = doc.sizeLink?.clientSize
    ? { companySize: doc.sizeLink?.clientSize }
    : {};

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={tracking}
      templateName="EventsPage"
    >
      <div className="pb-104">
        <Hero {...morphism(schemaHero, hero)} />
        <EventList datoEvents={datoEvents} {...pageData} />
      </div>
    </Layout>
  );
};

export default EventsPage;
