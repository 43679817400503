import { linkResolver } from '../../../utils/linkResolver';

export interface Event {
  title: string;
  subtitle: string;
  linkTitle: string;
  url: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  image: Thumbnail;
  eventType: EventType;
  subject?: string;
  logos: Logo[];
  keepShowingCta: boolean;
  color: string;
}

export interface Thumbnail {
  url: string;
  alt?: string | null;
  width: number;
  height: number;
}

export interface Logo {
  normal: {
    url: string;
    alt: string | null;
    width: number;
    height: number;
  };
}

export interface EventType {
  singular: string;
  plural: string;
  color: string;
}

export interface DatoEvent {
  __typename: string;
  id: string;
  internalLink: any | null;
  _allTitleLocales: Array<{ value: string }>;
  _allSubtitleLocales: Array<{ value: string }>;
  _allLinkTitleLocales: Array<{ value: string }>;
  linkUrl?: string;
  startDate: string;
  endDate: string;
  thumbnail: Thumbnail;
  eventType: EventType;
  subject?: { subject: string } | null;
  keepShowingCta: boolean;
  logos: Array<Logo>;
}

const isEqual = (date1: Date, date2: Date): boolean =>
  date1.getTime() === date2.getTime();
const isAfter = (date1: Date, date2: Date): boolean => date1 > date2;
const isBefore = (date1: Date, date2: Date): boolean => date1 < date2;
const isFuture = (date: Date): boolean => date > new Date();
const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const formatDate = (date: Date, locale: string): string => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date);
};

export const getEventUrl = (
  datoEvent: DatoEvent,
  locale: string,
): string | undefined => {
  if (datoEvent.linkUrl) return datoEvent.linkUrl;
  else if (datoEvent.internalLink)
    return linkResolver(datoEvent.internalLink, locale);
  return undefined;
};

export const getEvents = (datoEvents: DatoEvent[], locale: string): Event[] => {
  const events = datoEvents.map(
    (event): Event => ({
      title: event._allTitleLocales[0].value,
      subtitle: event._allSubtitleLocales[0].value,
      linkTitle: event._allLinkTitleLocales[0].value,
      url: getEventUrl(event, locale),
      startDate: event.startDate ? new Date(event.startDate) : undefined,
      endDate: event.endDate ? new Date(event.endDate) : undefined,
      image: event.thumbnail,
      eventType: event.eventType,
      subject: event.subject?.subject,
      color: event.eventType.color,
      logos: event.logos.map((logo: any) => logo.normal),
      keepShowingCta: event.keepShowingCta,
    }),
  );
  events.sort((event1, event2) =>
    sortEventDate(event1.endDate, event2.endDate) || 0,
  );
  return events;
};

export const getEventTypes = (datoEvents: DatoEvent[]): string[] => {
  const eventList = datoEvents.reduce((types: string[], event: DatoEvent) => {
    const eventType = event.eventType.plural;
    if (eventType && !types.includes(eventType)) {
      types.push(eventType);
    }
    return types;
  }, []);
  return eventList;
};

export const getDateDisplay = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  locale = 'en',
): string | undefined => {
  if (!startDate || !endDate) {
    return undefined;
  }
  if (isEqual(startDate, endDate)) {
    return formatDate(endDate, locale);
  } else {
    return `${formatDate(startDate, locale)} - ${formatDate(endDate, locale)}`;
  }
};

export const sortEventDate = (
  date1: Date | undefined,
  date2: Date | undefined,
): number | undefined => {
  if (!date1 || !date2) return undefined;
  if (isAfter(date1, date2)) return -1;
  else if (isBefore(date1, date2)) return 1;
  return 0;
};

export const isUpcoming = (date: Date | undefined): boolean => {
  if (!date) return false;
  return isFuture(date) || isToday(date);
};
